import axios from '@/http';

export default {
  /**
   * Method used to list platforms.
   *
   * @returns {Promise} The result in a promise.
   */
  get() {
    return axios.get('/platforms');
  },
  /**
   * Method used to list platforms.
   *
   * @returns {Promise} The result in a promise.
   */
  getPlatform(platformId) {
    return axios.get(`/platforms/${platformId}`);
  },
  /**
   * Method used to create a platform.
   *
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  create(payload) {
    return axios.post('/platforms', payload);
  },

  /**
   * Method used to create a platform.
   * @param {String} platformId The platform id.
   * @param {Object} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  update(platformId, payload) {
    return axios.patch(`/platforms/${platformId}`, payload);
  },

  /**
   * Method used to list platform's entities.
   *
   * @param {String} platform The platform id.
   *
   * @returns {Promise} The result in a promise.
   */
  getEntities(platform) {
    return axios.get(`/platforms/${platform}/entites`);
  },

  /**
   * Method used to list platform's domains.
   *
   * @param {String} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  getDomains({ platform, entity }) {
    return axios.get(`/platforms/${platform}/domains?idEntite=${entity}`);
  },

  /**
   * Method used to list domains' agencies.
   *
   * @param {String} platform The platform id.
   * @param {String} domain The domain id.
   *
   * @returns {Promise} The result in a promise.
   */
  getAgencies({ platform, domain }) {
    return axios.get(`/platforms/${platform}/domains/${domain}/agencies`);
  },
  /**
   * Method used to list delete platform.
   *
   * @param {String} platformId The platform id.
   *
   * @returns {Promise} The result in a promise.
   */
  deletePlatform(platformId) {
    return axios.delete(`/platforms/${platformId}`);
  },
};
