/* ============
 * platform state
 * ============
 *
 * The initial state of the platform module.
 */

export default {
  platforms: [],
  entities: [],
  domains: [],
  agencies: [],
  platform: [],
};
