<template>
  <div>
    <ProgressBar />
    <EuiLayout>
      <template #sidenav>
        <div
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
          @focusout="isHover = true"
          @focusin="isHover = false"
        >
          <EuiSidenav
            v-model="isSidebarOpen"
            :mini-variant="!isOpen && !isHover"
            :class="{ 'sidenav-hover': isHover && !isOpen }"
            @update:miniVariant="isOpen = !isOpen"
          >
            <EuiNavigationMini v-if="!isOpen && !isHover">
              <EuiNavigationMiniItem
                to="/home"
                :class="{ 'router-link-active': $route.name === 'Home' }"
              >
                <SVGIcon :src="require('@/assets/img/icons/home.svg')" :width="24" :height="24" />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/companies"
                :class="{ 'router-link-active': $route.name === 'Companies' }"
              >
                <SVGIcon
                  :src="require('@/assets/img/icons/checklist.svg')"
                  :width="24"
                  :height="24"
                />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                to="/accounts"
                :class="{ 'router-link-active': $route.name === 'Accounts' }"
              >
                <SVGIcon
                  :src="require('@/assets/img/icons/configuration.svg')"
                  :width="24"
                  :height="24"
                />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'User' && level === 1"
                :to="'/company/' + account.company"
                :class="{ 'router-link-active': $route.name === 'Company' }"
              >
                <SVGIcon
                  :src="require('@/assets/img/icons/company_circle.svg')"
                  :width="24"
                  :height="24"
                />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'Admin'"
                to="/create/company"
                :class="{ 'router-link-active': $route.name === 'CreateCompany' }"
              >
                <SVGIcon
                  :src="require('@/assets/img/icons/company_circle.svg')"
                  :width="24"
                  :height="24"
                />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'Admin'"
                to="/create/account"
                :class="{ 'router-link-active': $route.name === 'CreateAccount' }"
              >
                <SVGIcon
                  :src="require('@/assets/img/icons/admin_avatar_full.svg')"
                  :width="24"
                  :height="24"
                />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/offers"
                :class="{ 'router-link-active': $route.name === 'Offers' }"
              >
                <SVGIcon :src="require('@/assets/img/icons/offers.svg')" :width="24" :height="24" />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/platforms"
                :class="{ 'router-link-active': $route.name === 'Platforms' }"
              >
                <SVGIcon :src="require('@/assets/img/icons/platforms.svg')" :width="24" :height="24" />
              </EuiNavigationMiniItem>
              <EuiNavigationMiniItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/subscriptionHistory"
                :class="{ 'router-link-active': $route.name === 'History' }"
              >
                <SVGIcon :src="require('@/assets/img/icons/history.svg')" :width="24" :height="24" />
              </EuiNavigationMiniItem>
              <div class="eui-m-navigationMiniItem eui-u-mt-auto">
                <button class="eui-m-navigationMiniItem__button" type="button">
                  <div class="eui-m-navigationMiniItem__content">
                    <SVGIcon
                      :src="require('@/assets/img/icons/help_circle.svg')"
                      :width="24"
                      :height="24"
                    />
                  </div>
                </button>
              </div>
            </EuiNavigationMini>

            <EuiNavigation v-else>
              <EuiNavigationItem
                to="/home"
                :class="{ 'router-link-active': $route.name === 'Home' }"
              >
                <template #graphic>
                  <SVGIcon :src="require('@/assets/img/icons/home.svg')" :width="24" :height="24" />
                </template>
                Accueil
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/companies"
                :class="{ 'router-link-active': $route.name === 'Companies' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/checklist.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Sociétés
              </EuiNavigationItem>
              <EuiNavigationItem
                to="/accounts"
                :class="{ 'router-link-active': $route.name === 'Accounts' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/configuration.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                {{ account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin' ? 'Comptes API' : 'Mes comptes API' }}
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'User' && level === 1"
                :to="'/company/' + account.company"
                :class="{ 'router-link-active': $route.name === 'Company' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/company_circle.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Ma société
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'Admin'"
                to="/create/company"
                :class="{ 'router-link-active': $route.name === 'CreateCompany' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/company_circle.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Créer une société
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'Admin'"
                to="/create/account"
                :class="{ 'router-link-active': $route.name === 'CreateAccount' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/admin_avatar_full.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Créer un compte
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/offers"
                :class="{ 'router-link-active': $route.name === 'Offers' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/offers.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Les offres
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/platforms"
                :class="{ 'router-link-active': $route.name === 'Platforms' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/platforms.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Plateformes
              </EuiNavigationItem>
              <EuiNavigationItem
                v-if="account.type === 'Admin' || account.type === 'SupportReadOnlyAdmin'"
                to="/subscriptionHistory"
                :class="{ 'router-link-active': $route.name === 'History' }"
              >
                <template #graphic>
                  <SVGIcon
                    :src="require('@/assets/img/icons/history.svg')"
                    :width="24"
                    :height="24"
                  />
                </template>
                Historique
              </EuiNavigationItem>
              <EuiDropdownMenu
                :close-on-click-inside="false"
                placement="right-end"
                class="custom-dropdown about eui-u-mt-auto"
              >
                <EuiList>
                  <EuiListItem @click="open('https://silae-api.document360.io/')">
                    <div>
                      Accéder à la
                      <span class="link">documentation API</span>
                    </div>
                    <SVGIcon
                      style="color: #2b627b"
                      class="eui-u-mx-2 eui-u-my-1"
                      :src="require('@/assets/img/icons/open_in_new.svg')"
                      :width="24"
                      :height="24"
                    />
                  </EuiListItem>
                  <EuiListItem>
                    <div @click.stop="mailto" @keypress.stop="mailto">
                      Demande d'assistance<br />
                      <span class="link">support.api@silae.fr</span>
                    </div>
                    <SVGIcon
                      class="eui-u-cursor-copy eui-u-mx-2 eui-u-my-1"
                      :style="{ color: copied.mail ? '#327C31' : '#2B627B' }"
                      :src="
                        copied.mail
                          ? require('@/assets/img/icons/validate_full.svg')
                          : require('@/assets/img/icons/copy.svg')
                      "
                      :width="24"
                      :height="24"
                      @click="copy('support.api@silae.fr', 'mail')"
                    />
                  </EuiListItem>
                  <EuiDivider secondary />
                  <EuiListItem class="eui-u-cursor-default">
                    <b>Version de l'application</b>
                  </EuiListItem><EuiListItem class="eui-u-cursor-copy" @click="copy(pkg.version, 'front')">
                    <div><b>Front</b> {{ pkg.version }}</div>
                    <SVGIcon
                      class="eui-u-mx-2 eui-u-my-1"
                      :style="{ color: copied.front ? '#327C31' : '#2B627B' }"
                      :src="
                        copied.front
                          ? require('@/assets/img/icons/validate_full.svg')
                          : require('@/assets/img/icons/copy.svg')
                      "
                      :width="24"
                      :height="24"
                    />
                  </EuiListItem><EuiListItem class="eui-u-cursor-copy" @click="copy(version, 'back')">
                    <div><b>Back</b> {{ version ?? 'inconnue' }}</div>
                    <SVGIcon
                      class="eui-u-mx-2 eui-u-my-1"
                      :style="{ color: copied.back ? '#327C31' : '#2B627B' }"
                      :src="
                        copied.back
                          ? require('@/assets/img/icons/validate_full.svg')
                          : require('@/assets/img/icons/copy.svg')
                      "
                      :width="24"
                      :height="24"
                    />
                  </EuiListItem>
                </EuiList>
                <template #anchor>
                  <li class="eui-a-navigationItem">
                    <div class="eui-a-navigationItem__graphic">
                      <SVGIcon
                        :src="require('@/assets/img/icons/help_circle.svg')"
                        :width="24"
                        :height="24"
                      />
                    </div>
                    À propos
                  </li>
                </template>
              </EuiDropdownMenu>
            </EuiNavigation>
          </EuiSidenav>
        </div>
      </template>

      <template #header>
        <nav class="topbar">
          <router-link to="/">
            <img src="@/assets/img/logo.svg" alt="Logo" class="logo" />
          </router-link>
          <div class="eui-u-mr-6">
            <EuiDropdownMenu placement="bottom-end" class="custom-dropdown">
              <EuiList>
                <EuiListItem :title="account.mail" class="mail">
                  <SVGIcon
                    :src="require('@/assets/img/icons/default_avatar_full.svg')"
                    class="eui-u-mr-2"
                    :width="24"
                    :height="24"
                  />
                  <div class="eui-u-truncate">
                    {{ account.mail }}
                  </div>
                </EuiListItem>
                <EuiDivider secondary />
                <EuiListItem @click="onLogout" @keypress="onLogout">
                  <SVGIcon
                    :src="require('@/assets/img/icons/power.svg')"
                    class="eui-u-mr-2"
                    :width="24"
                    :height="24"
                  />
                  <div>Déconnexion</div>
                </EuiListItem>
              </EuiList>
              <template #anchor>
                <div class="eui-u-cursor-pointer">
                  <AvatarComponent :username="account.name" class="eui-u-mr-2" :size="32" />{{
                    account.name
                  }}
                </div>
              </template>
            </EuiDropdownMenu>
          </div>
        </nav>
        <EuiBreadcrumb>
          <span v-for="(route, index) of navigation" :key="route.route">
            <EuiBreadcrumbItem @click="navigate(route.path, index)">{{ route.meta.title }}
            </EuiBreadcrumbItem>
            <span v-if="index !== navigation.length - 1">&nbsp;>&nbsp;</span>
          </span>
        </EuiBreadcrumb>
      </template>

      <EuiContainer>
        <slot />
      </EuiContainer>
    </EuiLayout>

    <div class="notifications">
      <transition-group name="fade">
        <EuiAlert
          v-for="(notification, index) of notifications"
          :key="`alert${index}`"
          class="m-1"
          :color="notification.color"
          full-alert
          @click:close="removeNotification(index)"
        >
          <span>{{ notification.content }}</span>
          <template v-if="notification.route" #actions>
            <EuiButton @click="$router.push(notification.route)">
              Voir
            </EuiButton>
          </template>
        </EuiAlert>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { REMOVE_NOTIFICATION, BACK_NAVIGATION } from '@/store/modules/application/types';
import { LOGOUT } from '@/store/modules/auth/types';
import pkg from '../../package.json';
import ProgressBar from './ProgressBar.vue';
import AvatarComponent from './AvatarComponent.vue';
import SVGIcon from './SVGIcon.vue';

export default {
  name: 'TheLayout',
  components: { ProgressBar, AvatarComponent, SVGIcon },
  data() {
    return {
      pkg,
      isSidebarOpen: true,
      isHover: false,
      isOpen: false,
      copied: {
        mail: false,
        front: false,
        back: false,
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.auth.account,
      navigation: (state) => state.application.navigation.filter((el) => el),
      notifications: (state) => state.application.notifications,
      version: (state) => state.application.version,
    }),
    ...mapGetters({
      level: 'auth/level',
    }),

  },
  methods: {
    ...mapMutations({
      removeNotification: `application/${REMOVE_NOTIFICATION}`,
      backNavigation: `application/${BACK_NAVIGATION}`,
      logout: `auth/${LOGOUT}`,
    }),
    onLogout() {
      this.$msal.instance.logoutRedirect({ account: this.$msal.accounts[0] });
    },
    open(url, target = '_blank') {
      window.open(url, target, 'noopener noreferrer');
    },
    navigate(route, index) {
      this.backNavigation(index);
      this.$router.push(route);
    },
    async copy(text, index) {
      try {
        await navigator.clipboard.writeText(text);
        this.copied[index] = true;
        setTimeout(() => {
          this.copied[index] = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    mailto() {
      window.location.href = 'mailto:support.api@silae.fr';
    },
  },
};
</script>
<style lang="scss" scoped>
.notifications {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2000;
}

.topbar {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 0px 1px 8px 0px #4a4a4a29;
}

.logo {
  margin-left: 26px;
}

.sidenav-hover {
  margin-right: -203px;
}

.eui-m-alert {
  max-width: 500px;
}
</style>
<style lang="scss">
.custom-dropdown {
  .eui-m-dropdownMenu__surface {
    max-width: 370px;
    margin-right: 0 !important;
    margin-top: 16px !important;
    padding: 0;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    .eui-a-listItem {
      &.mail {
        cursor: help;
      }
      color: #626262;
      padding: 8px 16px !important;
      line-height: 0;
      cursor: pointer;
      flex-direction: row-reverse;
      a,
      .link {
        cursor: pointer;
        color: #0051ff;
        text-decoration: underline;
        text-underline-position: under;
      }
      margin: 8px;
      line-height: inherit;
      justify-content: flex-start !important;
      flex-direction: row;

      &:hover {
        background: #f4f7f8;
        color: #626262;
      }
      div {
        flex: 1 1 auto;
      }
    }
  }
}
</style>
