/* ============
 * ApiAccount getters
 * ============
 *
 * The getters available for the apiAccount module.
 */

export default {
  get: (state) => state.all,
  getOffers: (state) => state.offers,
  getSoftwares: (state) => state.softwares.sort(),
};
