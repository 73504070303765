/* ============
 * Company mutations
 * ============
 *
 * The mutations available for the company module.
 */

import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_COMPANIES](state, payload) {
    state.all = payload;
  },

  [types.SET_COMPANY](state, payload) {
    state.company = payload;
  },

  [types.SET_CURRENT_PAGE](state, value) {
    state.all.pagination.currentPage = value;
  },

  [types.SET_LIMIT](state, value) {
    state.all.pagination.limit = value;
  },

  [types.SET_ACCOUNTS](state, value) {
    state.accounts = value;
  },

  [types.SET_COMPANY_ALL_ACCOUNTS](state, value) {
    state.company.accounts = value;
  },

  [types.SET_CURRENT_PAGE_ACCOUNTS](state, value) {
    state.accounts.pagination.currentPage = value;
  },

  [types.SET_LIMIT_ACCOUNTS](state, value) {
    state.accounts.pagination.limit = value;
  },

  [types.SET_API_CONTACT_MAIL](state, mail) {
    Vue.set(state.company, 'apiContactMail', mail);
  },

  [types.SET_NAME](state, name) {
    state.company.name = name;
  },

  [types.SET_SILAE_CLIENT_ID](state, id) {
    Vue.set(state.company, 'silaeClientId', id);
  },

  [types.SET_FILTERS](state, value) {
    state.filters = value;
  },
};
