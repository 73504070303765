/* ============
 * platform getters
 * ============
 *
 * The getters available for the platform module.
 */

export default {
  getPlatforms: (state) => state.platforms,
  getEntities: (state) => state.entities,
  getDomains: (state) => state.domains.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  }),
  getAgencies: (state) => state.agencies,
};
