/* ============
 * platform actions
 * ============
 *
 * The actions available for the platform module.
 */

import api from '@/api/History';
import * as types from './types';

export default {
  /**
   * Action which will get all subscription history.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} filter The filter to apply.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistory({ commit }, filter) {
    return api.getSubscriptionsHistory(filter).then((data) => {
      commit(types.SET_SUBSCRIPTIONSHISTORY, data.data);
      return data;
    });
  },
  /**
   * Action which will get one history detail.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} id History detail id.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistoryDetail({ commit }, id) {
    return api.getSubscriptionsHistoryDetail(id).then((data) => {
      commit(types.SET_SUBSCRIPTIONSHISTORY_DETAIL, data.data);
      return data;
    });
  },
  /**
   * Action which will get all history detail for a subscription.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Object} filter The filter to apply.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistoryDetails({ commit }, filter) {
    return api.getSubscriptionsHistoryDetails(filter).then((data) => {
      commit(types.SET_SUBSCRIPTIONSHISTORY_DETAILS, data.data);
      return data;
    });
  },
};
