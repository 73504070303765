import axios from '@/http';

export default {
  /**
   * Method used to get subscriptions history.
   *
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistory(filter) {
    return axios.post('/subscriptionsHistory', filter);
  },
  /**
   * Method used to get subscriptions history detail.
   *
   * @param {Object} id history detail id .
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistoryDetail(id) {
    return axios.get(`/subscriptionsHistoryDetail/${id}`);
  },
  /**
   * Method used to get subscriptions history detail.
   *
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistoryDetails(filter) {
    return axios.post('/subscriptionsHistoryDetails/', filter);
  },

  /**
   * Method used to get subscriptions history detail.
   *
   * @param {Object} filter The filter.
   *
   * @returns {Promise} The result in a promise.
   */
  getSubscriptionsHistoryExport(filter) {
    return axios.post('/subscriptionsHistoryExport/', filter);
  },
};
