/* ============
 * history mutations
 * ============
 *
 * The mutations available for the history module.
 */

// import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_SUBSCRIPTIONSHISTORY](state, payload) {
    state.subscriptionsHistoryData = payload;
    state.pagination = payload.pagination;
  },

  [types.SET_LIMIT](state, value) {
    state.pagination.limit = value;
  },

  [types.SET_CURRENT_PAGE](state, value) {
    state.pagination.currentPage = value;
  },

  [types.SET_SUBSCRIPTIONSHISTORY_DETAIL](state, value) {
    state.subscriptionsHistoryDetail = value;
  },

  [types.SET_SUBSCRIPTIONSHISTORY_DETAILS](state, value) {
    state.subscriptionsHistoryDetails = value;
  },

  [types.SET_LIMIT_DETAILS](state, value) {
    state.subscriptionsHistoryDetails.pagination.limit = value;
  },

  [types.SET_CURRENT_PAGE_DETAILS](state, value) {
    state.subscriptionsHistoryDetails.pagination.currentPage = value;
  },
};
