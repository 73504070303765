/* ============
 * platform mutations
 * ============
 *
 * The mutations available for the platform module.
 */

import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_PLATFORMS](state, payload) {
    state.platforms = payload;
  },

  [types.SET_PLATFORM](state, payload) {
    state.platform = payload;
  },

  [types.SET_ENTITIES](state, payload) {
    state.entities = payload;
  },

  [types.SET_DOMAINS](state, payload) {
    state.domains = payload;
  },

  [types.SET_AGENCIES](state, payload) {
    state.agencies = payload;
  },

  [types.SET_NAME](state, value) {
    state.platform.name = value;
  },

  [types.SET_URL](state, value) {
    state.platform.url = value;
  },

  [types.SET_PLATORM_CALLS](state, value) {
    Vue.set(state.platform, 'platformCalls', value);
  },

  [types.SET_PLATFORM_RENAWAL_PERIOD](state, value) {
    Vue.set(state.platform, 'platformRenewalPeriod', value);
  },

};
