import { extend } from 'vee-validate';
import {
  required, email, regex, between,
} from 'vee-validate/dist/rules';
import store from '@/store';

export default function setup() {
  extend('email', email);
  extend('required', required);
  extend('regex', regex);
  extend('between', between);
  extend('company', {
    validate: (value) => store.state.company.all?.companies?.some((el) => el.name === value),
    message: 'La société est invalide.',
  });
}
