/* ============
 * history state
 * ============
 *
 * The initial state of the history module.
 */
export default {
  subscriptionsHistoryData: [],
  filters: [
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'historyDate',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'subscriptionId',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'accountName',
    },
    {
      criteria: {
        type: 'ListStringValue',
        values: ['enabled', 'disabled', 'notActivated', 'error', 'blocked', null],
      },
      name: 'oldStatus',
    },
    {
      criteria: {
        type: 'ListStringValue',
        values: ['enabled', 'disabled', 'notActivated', 'error', 'blocked', null],
      },
      name: 'newStatus',
    }],
  pagination: [],
  // subscriptionStatus: ['enabled', 'disabled', 'notActivated', 'error', 'blocked', null],
  subscriptionsHistoryDetail: [],
  subscriptionsHistoryDetails: [],
};
