/* ============
 * Account state
 * ============
 *
 * The initial state of the account module.
 */

export default {
  account: {},
  subscriptions: [],
  subscription: {},
  bundles: {
    default: [],
    offer: [],
    all: [],
  },
  subscriptionsFilters: [
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'domain',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'dossiers',
    },
    {
      criteria: {
        type: 'StringValue',
        value: '',
      },
      name: 'id',
    },
    {
      criteria: {
        type: 'ListStringValue',
        values: ['enabled', 'disabled', 'notActivated', 'error'],
      },
      name: 'status',
    },
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'creationDate',
    },
    {
      criteria: {
        type: 'DateRange',
        min: '',
        max: '',
      },
      name: 'modificationDate',
    },
  ],
};
