import http from '@/http';
import store from '@/store';
import eventBus from '@/plugins/eventBus';
import { useMsal } from '@/plugins/msal';

export default function setup() {
  http.interceptors.request.use((config) => {
    if (config.enableLoader === undefined || config.enableLoader === true) {
      eventBus.$emit('asyncComponentLoading');
    }
    // Updating token before sending request if it has expired
    return new Promise((resolve) => {
      if (store.state.auth.account.expiration - Date.now() < 60) {
        const { instance, accounts } = useMsal();
        instance
          .acquireTokenSilent({ account: accounts[0] })
          .then((data) => {
            console.debug(data);
            store.dispatch('auth/login', {
              token: data.idToken,
              account: {
                name: data.idTokenClaims.name,
                type: data.idTokenClaims.role,
                id: data.idTokenClaims.accountId,
                mail: data.idTokenClaims.email,
                company: data.idTokenClaims.companyId,
                expiration: data.idTokenClaims.exp * 1000,
              },
            });
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${data.idToken}`;
            resolve(config);
          })
          .catch(() => instance.loginRedirect());
      } else resolve(config);
    });
  });
  http.interceptors.response.use(
    (response) => {
      if (response.config.enableLoader === undefined || response.config.enableLoader === true) {
        eventBus.$emit('asyncComponentLoaded');
      }
      return response;
    },
    (error) => {
      if (error.response?.status) {
        switch (error.response.status) {
          case 400:
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 400 : Bad Request',
              color: 'error',
            });
            break;
          case 401:
            // Unauthorized
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 401 : Unauthorized',
              color: 'error',
            });
            break;
          case 403:
            // Forbidden
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 403 : Forbidden',
              color: 'error',
            });
            break;
          case 404:
            // Not Found
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 404 : Not Found',
              color: 'error',
            });
            break;
          case 429:
            // Too many request
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 429 : Too many request',
              color: 'error',
            });
            break;
          case 500:
            // Internal Server Error
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 500 : Internal Server Error',
              color: 'error',
            });
            break;
          case 502:
            // Bad Gateway
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur 502 : Bad Gateway',
              color: 'error',
            });
            break;
          default:
            store.dispatch('application/addNotification', {
              content: error.response.data.Message ?? 'Erreur inattendue',
              color: 'error',
            });
            break;
        }
      }
      eventBus.$emit('asyncComponentLoaded');
      return Promise.reject(error);
    },
  );
}
