/* ============
 * platform getters
 * ============
 *
 * The getters available for the platform module.
 */

export default {
  getSubscriptionHistory: (state) => state.subscriptionsHistoryData,
};
