/* ============
 * ApiAccount mutations
 * ============
 *
 * The mutations available for the apiAccount module.
 */

import Vue from 'vue';
import * as types from './types';

export default {
  [types.SET_ALL](state, payload) {
    state.all = payload;
  },

  [types.SET_OFFERS](state, payload) {
    state.offers = payload;
  },

  [types.SET_CURRENT_PAGE](state, value) {
    state.all.pagination.currentPage = value;
  },

  [types.SET_LIMIT](state, value) {
    state.all.pagination.limit = value;
  },

  [types.SET_FILTERS](state, value) {
    state.filters = value;
  },

  [types.SET_SOFTWARES](state, value) {
    state.softwares = value;
  },

  [types.SET_DELETED](state, value) {
    state.deleted = value;
  },

  [types.NEW_FILTER](state, value) {
    Vue.set(state.filters, value, JSON.parse(JSON.stringify(state.filters.model)));
  },
};
