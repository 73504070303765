/* ============
 * platform actions
 * ============
 *
 * The actions available for the platform module.
 */

import api from '@/api/Platform';
import * as types from './types';

export default {
  /**
   * Action which will get all platforms.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  async getPlatforms({ commit }) {
    return api.get().then((data) => {
      commit(types.SET_PLATFORMS, data.data);
      return data;
    });
  },

  /**
   * Action which will create a platform.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  async createPlatform({ dispatch }, payload) {
    return api.create(payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: `Vous avez créé la plateforme ${payload.name} !`,
          color: 'success',
          route: `/platform/${data.data.id}`,
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will get all platforms.
   *
   * @param {Function} commit Commit function to update the store.
   *
   * @returns {Promise} The result in a promise.
   */
  async updatePlatform({ dispatch }, { platformId, payload }) {
    return api.update(platformId, payload).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez modifié cette plateforme !',
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },

  /**
   * Action which will get a single platform.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {Boolean} includeUsers true to include users.
   * @param {Boolean} includeAccounts true to include accounts.
   *
   * @returns {Promise} The result in a promise.
   */
  async getPlatform({ commit }, platformId) {
    return api.getPlatform(platformId).then((data) => {
      commit(types.SET_PLATFORM, data.data);
      return data;
    });
  },
  /**
   * Action which will get all domains of a platform.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} platform The platform id.
   *
   * @returns {Promise} The result in a promise.
   */
  async getEntities({ commit }, platform) {
    return api.getEntities(platform).then((data) => {
      commit(types.SET_ENTITIES, data.data);
      return data;
    });
  },

  /**
   * Action which will get all domains of a platform.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  async getDomains({ commit }, payload) {
    return api.getDomains(payload).then((data) => {
      commit(types.SET_DOMAINS, data.data);
      return data;
    });
  },

  /**
   * Action which will get all agencies of a domain.
   *
   * @param {Function} commit Commit function to update the store.
   * @param {String} payload The payload.
   *
   * @returns {Promise} The result in a promise.
   */
  async getAgencies({ commit }, payload) {
    return api.getAgencies(payload).then((data) => {
      commit(types.SET_AGENCIES, data.data);
      return data;
    });
  },
  /**
   * Action which will delete a platform.
   *
   * @param {Function} dispatch Dispatch function to call actions.
   * @param {String} platformId The platform id.
   *
   * @returns {Promise} The result in a promise.
   */
  async deletePlatform({ dispatch }, platformId) {
    return api.deletePlatform(platformId).then((data) => {
      dispatch(
        'application/addNotification',
        {
          content: 'Vous avez supprimé la plateforme avec succès !',
          color: 'success',
        },
        { root: true },
      );
      return data;
    });
  },
};
